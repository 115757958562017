import React from 'react';
import SectionTitle from 'components/Shared/SectionTitle';
import Project from 'components/Projects/project';

/* Image */
import Tipolator1x from 'Images/Tipolator/tipolator1x.png';
import Tipolator2x from 'Images/Tipolator/tipolator2x.png';
import Tipolator3x from 'Images/Tipolator/tipolator3x.png';
import PersonalImage1x from 'Images/PersonalPage/personalPage1x.jpg';
import PersonalImage2x from 'Images/PersonalPage/personalPage2x.jpg';
import PersonalImage3x from 'Images/PersonalPage/personalPage3x.jpg';

/* Style */
import 'components/Projects/projects.css';

const Projects = () => {
  const tipolatorDetails = {
    projectTitle: 'Tipolator',
    images: [Tipolator1x, Tipolator2x, Tipolator3x],
    description: 'A free tip calculator available on Apple\'s ',
    endDescription: ' that currently has 289 downloads. This is an ongoing project that I have been periodically updating with new features and backgrounds.',
    techStack: [
      'GitHub',
      'Figma',
      'Swift 4',
      'XCode',
    ],
    link: 'https://itunes.apple.com/us/app/tipolator/id1448661585?mt=8',
    linkText: 'App Store',
  };

  const personalWebsiteDetails = {
    projectTitle: 'Personal Website',
    images: [PersonalImage1x, PersonalImage2x, PersonalImage3x],
    description: 'Designed to highlight resume elements and various projects. Original site was created with HTML and CSS using Atom. New site has been refactored with React and was built using WebStorm.',
    endDescription: '',
    techStack: [
      'AWS (S3, Route 53, CloudFront)',
      'CSS',
      'GitHub',
      'React',
      'Typescript',
      'WebStorm',
    ],
    link: '',
    linkText: '',
  };

  return (
    <div className="projects-container">
      <SectionTitle sectionText="Projects" />

      <div className="projects-projects-container">
        <Project
          projectTitle={tipolatorDetails.projectTitle}
          images={tipolatorDetails.images}
          description={tipolatorDetails.description}
          endDescription={tipolatorDetails.endDescription}
          techStack={tipolatorDetails.techStack}
          link={tipolatorDetails.link}
          linkText={tipolatorDetails.linkText}
        />

        <Project
          projectTitle={personalWebsiteDetails.projectTitle}
          images={personalWebsiteDetails.images}
          description={personalWebsiteDetails.description}
          endDescription={personalWebsiteDetails.endDescription}
          techStack={personalWebsiteDetails.techStack}
          link={personalWebsiteDetails.link}
          linkText={personalWebsiteDetails.linkText}
        />
      </div>
    </div>
  );
};

export default Projects;