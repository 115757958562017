import React from 'react';
import 'components/Footer/footer.css';

const Footer = () => {
  return (
    <div className="footer-container">
      <p>Brian Phair 2019 - 2020. All rights reserved.</p>
    </div>
  );
};

export default Footer;