/* eslint-disable */
import React from 'react';
import 'components/Education/education.css';
import SectionTitle from 'components/Shared/SectionTitle';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import College from 'components/Education/college';

const Index = () => {
  const oregonState = {
    collegeName: 'Oregon State University',
    yearsAttended: 'Aug 2015 - Mar 2019',
    degree: 'Bachelor of Science in Computer Science',
  };
  const westLiberty = {
    collegeName: 'West Liberty University',
    yearsAttended: 'Aug 2010 - Mar 2014',
    degree: 'Bachelor of Science in Chemistry',
  };
  return (
    <div className="education-container">
      <SectionTitle sectionText="Education" />
      <div className="education-colleges">
        <div className="education-college-one">
          <College collegeDetails={westLiberty} />
        </div>
        <div className="education-college-two">
          <College collegeDetails={oregonState} />
        </div>
      </div>
    </div>
  );
};

export default Index;