/* eslint-disable */
import React from 'react';
import 'components/Welcome/index.css';
import { Parallax, Background } from 'react-parallax';
import BackgroundImage from '../../Images/DCPanorama1.jpg';
import Styled from 'styled-components';

class WelcomeSection extends React.Component {
  render() {
    const objectiveText = 'Software engineer with professional experience in React, JavaScript, Redux, GitHub, Agile methodology, and developing web-based security management software.';
    return (
      <div className="ImageSection">
        <h2 className="helloMessage">
          <span className="fade-in one">Hello</span>
          <span className="fade-in two">, I'm Brian</span>
        </h2>
        <div className="object-text fade-in three">{objectiveText}</div>
      </div>
    );
  }
}

const CustomParallax = Styled(Parallax)`
  padding: 50px 0 0 0;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
`;

export default WelcomeSection;