/* eslint-disable */
import React from 'react';

type projectDetails = {
  description: string,
  endDescription: string,
  images: string[],
  link: string,
  linkText: string,
  projectTitle: string,
  techStack: string[],
};

const Project = (props: projectDetails) => {
  const {
    description,
    endDescription,
    images,
    link,
    linkText,
    projectTitle,
    techStack,
  } = props;
  return (
    <div className="project-container">
      <div className="project-project-title">{projectTitle}</div>
      <img
        className="project-image"
        src={images[0]}
        srcSet={
          `${images[0]} 1x,
          ${images[1]} 2x,
          ${images[2]} 3x`
        }
        alt="project"
      />
      <div>
        <div className="project-section-title">Description</div>
        {endDescription === '' ? (
          <div>{description}</div>
        ) : (
          <div>
            {description}
            <a href={link} target="_blank" rel="noopener noreferrer">{linkText}</a>
            {endDescription}
          </div>
        )
        }
      </div>
      <div>
        <div className="project-section-title">Tech Stack</div>
        <div className="project-tech-stack-section">
          {techStack.map(tech => {
            return <span className="project-custom-skill">{tech}</span>
          })}
        </div>
      </div>
    </div>
  );
};

export default Project;