/* eslint-disable */
import React from 'react';
import 'components/Education/education.css';

type CollegeData = {
  collegeDetails: {
    collegeName: string,
    yearsAttended: string,
    degree: string,
  }
};

const College = ({ collegeDetails } : CollegeData) => {
  return (
    <React.Fragment>
      <div className="college-college-name">{collegeDetails.collegeName}</div>
      <div className="college-years-attended">{collegeDetails.yearsAttended}</div>
      <div className="college-degree">{collegeDetails.degree}</div>
    </React.Fragment>
  );
};

export default College;