import React from 'react';
import 'components/Links/links.css';
import LinkedInLogo from 'Images/In-Black-34px-R.png';
import GitHubLogo from 'Images/GitHub-Mark-32px.png';
import Tooltip from '@material-ui/core/Tooltip';

const Index = () => {
  return (
    <div className="links-container">

      <Tooltip title="GitHub" arrow>
        <a href="https://github.com/bsphair" target="_blank" rel="noopener noreferrer" className="links-image">
          <img src={GitHubLogo} alt="GitHub"/>
        </a>
      </Tooltip>

      <Tooltip title="LinkedIn" arrow>
        <a href="https://www.linkedin.com/in/brian-phair/" target="_blank" rel="noopener noreferrer" className="links-image">
          <img src={LinkedInLogo} alt="icons"/>
        </a>
      </Tooltip>

    </div>
  );
};

export default Index;