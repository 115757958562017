import React from 'react';
import styled from 'styled-components';

type SectionText = {
  sectionText: string,
};

const SectionTitle = ({ sectionText } : SectionText) => {
  return (
    <CustomText>
      {sectionText}
    </CustomText>
  );
};

const CustomText = styled.div`
    text-align: center;
    font-size: 45px;
    font-weight: 500;
`;

export default SectionTitle;