import React from 'react';
import 'components/About/index.css';
import PersonalImage from 'Images/Personal_Image/Personal_Image2.jpg';

const AboutSection = () => {
  const locationText = 'Gaithersburg, MD';
  const spareTimeText = 'Spend time with my wife and our four cats, hiking, photography';
  return (
    <div className="about-container">
      <div className="about-image-section">
        <img
          className="about-image"
          src={PersonalImage}
          alt="Personal"
        />
      </div>

      <div className="about-text-section">
        <span className="about-text-row-1">
          <div className="about-text-row-1-name">Brian Phair</div>
          <div>{locationText}</div>
        </span>


        <div className="about-degrees-section">
            <div className="about-degrees">
              <div className="about-wlu-section">
                <b>West Liberty University</b>
                <div>B.S. Chemistry</div>
              </div>
              <div className="about-osu-section">
                <b>Oregon State University</b>
                <div>B.S. Computer Science</div>
              </div>
            </div>
        </div>

        <div className="about-spare-time-section">
          <b>In my spare time</b>
          <div>{spareTimeText}</div>
        </div>
     </div>
    </div>
  );
};

export default AboutSection;