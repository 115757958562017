/* eslint-disable */
import React from 'react';
import SectionTitle from 'components/Shared/SectionTitle';
import 'components/Experience/Experience.css';
import Job from 'components/Experience/Job';
import styled from 'styled-components';

const Index = () => {
  const noblisDetails = {
    companyTitle: 'Noblis',
    positionTitle: 'UI/UX Developer',
    location: 'Reston, VA',
    time: 'Sep 2020 - Present',
    duties: ['Develop front-end features for multiple projects'],
    websiteAddress: 'https://noblis.org/',
    skills: [],
  };
  const tdiDetails = {
    companyTitle: 'Tetrad Digital Integrity',
    positionTitle: 'Associate Software Engineer',
    location: 'Washington, DC',
    time: 'Jun 2019 - Aug 2020',
    duties: [
      "Developed new front-end features and pages for the CnSight platform",
      "Lead daily stand-up and retro meetings",
      "Maximized text coverage with Jest/Enzyme/Nightwatch.js",
      "Quality service lead responsible for front end functionality"
    ],
    websiteAddress: 'https://www.tdisecurity.com/',
    skills: [
      'Agile',
      'Git/GitHub',
      'Jest',
      'Material-UI',
      'Nightwatch.js',
      'React',
      'Redux',
      'Slack',
      'Styled Components',
    ],
  };
  const smithersDetails = {
    companyTitle: 'Smithers Avanza',
    positionTitle: 'Scientist 1',
    location: 'Gaithersburg, MD',
    time: 'Oct 2016 - Nov 2018',
    duties: [
      "Analyzed serum samples for presence of antibodies",
      "Ordered office supplies and managed lab inventory levels",
      "Assisted in training lab personnel in various procedures",
      "Ensured documentation meets SOP/GLP guidelines",
      "Analyzed data and created/updated data tables using Watson LIMS and Microsoft Excel",
      "Wrote macro with Visual Basic that alerts team if inventory levels fall below certain levels",
      "Wrote Python script to optimize file management and data information"
    ],
    websiteAddress: 'http://www.smithersavanza.com/',
    skills: [
      'Communications',
      'Documentation',
      'Inventory',
      'Training',
    ],
  };
  const mylanDetails = {
    companyTitle: 'Mylan',
    positionTitle: 'QC Chemist',
    location: 'Morgantown, WV',
    time: 'Oct 2014 - May 2016',
    duties: [
      "Performed dissolution testing on raw/finished products for both quality control and drug release",
      "Reviewed data to ensure specifications were met",
      "Data entry into LIMS",
      "Ensured documentation met SOP/GLP guidelines"
    ],
    websiteAddress: 'http://www.mylan.com/',
    skills: [
      'Critical observation',
      'Documentation',
      'Flexibility',
      'Team work',
    ],
  };
  return (
    <div className="experience-section">
      <SectionTitle sectionText="Experience" />

      <Job jobDetails={noblisDetails} />
      <Job jobDetails={tdiDetails} />
      <Job jobDetails={smithersDetails} />
      <Job jobDetails={mylanDetails} />

    </div>
  );
};

const CustomSkill = styled.span`
    //background-color: #039be5;
    //background-color: #43a047;
    //background-color: #ff8f00;
    background-color: grey;
    color: white;
    padding: 3px 6px;
    border-radius: 5px;
    margin: 0 5px 5px 0;
    font-size: 0.9rem;
`;

export default Index;