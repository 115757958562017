import React from 'react';
import WelcomeSection from 'components/Welcome/index';
import AboutSection from 'components/About/index';
import ExperienceSection from 'components/Experience';
import Education from 'components/Education';
import Projects from 'components/Projects';
import Contact from 'components/Contact';
import Links from 'components/Links';

function App() {
  return (
    <div>

      <WelcomeSection />

      <AboutSection />

      <Links />

      <ExperienceSection />

      <Education />

      <Projects />

      <Contact />

    </div>
  );
}

export default App;
