import React from 'react';
import 'components/Contact/contact.css';
import SectionTitle from 'components/Shared/SectionTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const Index = () => {
  return (
    <div className="contact-container">
      <SectionTitle sectionText="Contact Me" />

      <form method="POST" action="http://formspree.io/bsphair@gmail.com" className="contact-form">

        <TextField className="contact-form-textfield" label="Name" variant="outlined" />
        <TextField className="contact-form-textfield" label="Email" name="_replyto" variant="outlined" />
        <TextField className="contact-form-textfield" label="Subject" name="_subject" variant="outlined" />
        <TextField
          className="contact-form-textfield"
          label="Message"
          variant="outlined"
          name="message"
          multiline
          rows={5}
        />
        <Button variant="contained" color="primary" value="submit" type="submit" >
          Send Message
        </Button>
      </form>
    </div>
  );
};

export default Index;