/* eslint-disable */
import React from 'react';
import 'components/Experience/Experience.css';

type JobDetails = {
  jobDetails: {
    companyTitle: string,
    positionTitle: string,
    location: string,
    time: string,
    duties: string[],
    websiteAddress: string,
    skills: string[],
  }
}

const Job = ({ jobDetails } : JobDetails) => {
  return (
    <div className="job-container">
      <div className="job-company-title-section">
        <div className="job-job-title">
          {jobDetails.companyTitle}
        </div>
        <div>
          {jobDetails.time}
        </div>
      </div>
      <div className="job-details-section">
        <div>
          {jobDetails.positionTitle} | {jobDetails.location}
        </div>
        <div>
          <ul>
            {jobDetails.duties.map(duty => {
              return (
                <li>
                  {duty}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="job-custom-skill-section">
          {jobDetails.skills.map(skill => <span className="job-custom-skill">{skill}</span>)}
        </div>
      </div>
    </div>
  );
};


export default Job;